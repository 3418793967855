@import "../../../../shared/util/variables";

.my-forms {
  display: grid;
  grid-template-rows: 15% 15% 70%;
  grid-template-columns: 100%;
  height: 100%;

  .title {
    color: $primary;
    font-family: $boldNeutrifPro;
    font-size: calc(24px + 0.1vw);
    padding: 1.4em 1em;
  }

  .main {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;

    .container {
      background-color: white;
      border: 1px solid $grey2;
      margin: 2.5% 2%;
      padding: 0.5% 2%;

      .tableRoot {
        th {
          height: 5vh;
        }
        .titleColumn {
          background-color: transparent;
          border-bottom: solid 1px $grey1;
          color: $grey4;
          font-family: $lightNeutrifPro;
          font-size: 12px;
          margin: 0;
          padding: 0;
          text-align: start;
          width: 16vw;
        }
        td {
          border-top: solid 1px $grey2;
          color: $darkGrey;
          font-family: $mediumNeutrifPro;
          font-size: 14px;
          height: 50px;
          padding: 0px;
        }
        .date {
          color: $grey4;
          font-family: $lightNeutrifPro;
        }
      }
      .navigationTableButton {
        border-radius: 1px;
        border: 1px solid $grey1;
        color: $grey1;
        padding: 4px 8px;
      }
      .navigationTableButton.Mui-disabled {
        background-color: $grey2;
        color: $grey1;
      }
      .navigationTableCaption,
      .selectTableText {
        color: $grey4;
        font-family: $lightNeutrifPro;
        font-size: 14px;
      }
      .selectTableIcon {
        color: $grey4;
      }
      .selectTableText li {
        font-family: $lightNeutrifPro;
      }
    }
  }
}

.modal-text {
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  font-size: 14px;
  font-style: normal;
  line-height: 25px;
  margin-top: 5px;
  text-align: left;
}

.modal-title {
  color: $primary;
  font-family: $regularNeutrifPro;
  font-size: 16px;
  text-align: left;
}