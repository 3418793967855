@import "../../../../shared/util/variables";

.languages-list-item-unactive {
  cursor: pointer;
  border-top: 1px solid $grey2;
  background-color: transparent;

  .icon {
    float: right;
    font-size: 20px;
    vertical-align: middle;
  }

  .icon.add {
    color: $grey2;
  }

  .icon.remove {
    color: $red;
  }

  .item-button {
    background-color: transparent;
    border: none;
    color: $darkGrey;
    cursor: pointer;
    font-family: $regularNeutrifPro;
    font-size: 12px;
    outline: none;
    padding: 12px;
    text-align: left;
    width: 100%;
  }
}

.languages-list-item-unactive.selected {
  background-color: rgba(0, 204, 102, 0.15);
}

.languages-list-item-active {
  cursor: pointer;
  border-top: 1px solid $grey2;
  background-color: transparent;

  .icon {
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
  }

  .icon.add {
    color: $grey2;
  }

  .icon.remove {
    color: $red;
    float: right;
  }

  .item-button {
    background-color: transparent;
    border: none;
    color: $darkGrey;
    cursor: pointer;
    font-family: $regularNeutrifPro;
    font-size: 12px;
    height: 100%;
    outline: none;
    padding: 12px;
    text-align: left;
    width: 100%;
  }
}

.languages-list-item-active.selected {
  background-color: $green2;
}
