@import "../../../util/variables.scss";

.qr-bar-code-builder {
  padding: 0.2em 1em 1em 0;
  color: $darkGrey;

  .content {
    display: flex;
    align-items: center;

    .qrcode-icon {
      color: $darkGrey;
      margin-left: 0.5em;
    }

    .material-icons {
      font-size: 20px;
    }

    .input-qr-bar-code {
      display: flex;
      background-color: white;
      border: 1px solid $grey2;
      border-radius: 4px;
      min-width: 15vw;
      padding: 0.35em 0.1em;

      p {
        width: 90%;
      }

      .plus-icon {
        color: $grey1;
        margin: auto;
      }
    }
  }

  .qr-bar-code-icon {
    background-color: transparent;
  }
}

.mandatory-signal {
  font-size: calc(18px + 0.2vw);
  color: $red;
}
