@import "../../../util/variables";

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid $grey2;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 8px 8px 10px;
  font-size: calc(12px + 0.1vw);
  display: flex;
  align-items: center;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid $grey2;
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 115%;
  z-index: 1000;
  font-size: calc(12px + 0.1vw);
  color: $darkGrey;
  font-family: $regularNeutrifPro;
}

.Dropdown-menu::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
  background-color: $grey2;
}

.Dropdown-menu::-webkit-scrollbar-thumb {
  background: $darkGrey;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: $darkGrey;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: $grey3;
  color: $darkGrey;
}

.Dropdown-option.is-selected {
  background-color: $grey2;
  color: $darkGrey;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.Dropdown-placeholder {
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  display: inline-flex;
  vertical-align: middle;
  width: inherit;
}

.Dropdown-arrow-wrapper {
  display: inline-flex;
  float: right;
}

.open-close-arrow {
  font-size: 10px;
}
