@import "../../../util/variables";

#DescriptionPreference
{
  padding: 2ch;
  padding-bottom: 0;
}

#inputDescriptionPreference {
  border: 1px solid $grey2;
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  border-radius: 3px;
  width: 88.5%;
  outline: none;
}

.inputDescriptionPreference {
  border-radius: 3px;
  border: 1px solid $grey2;
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  font-size: 12px;
  height: 50px;
  outline: none;
  padding: 1ch 1.4ch;
  resize: none;
  width: 95%;
  &::-webkit-scrollbar {
    width: 1ch;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2.5px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
    background-color: $grey2;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
}


::-webkit-input-placeholder {
  color: $darkGrey;
  font-family: $regularNeutrifPro;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $darkGrey;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $darkGrey;
}
