@import "../../../util/variables";

.data-list-input {
  outline: none;
  border: none;
  width: 90%;
  padding: 10px;
  font-family: $regularNeutrifPro;
  color: $darkGrey;
  z-index: 1;
}

.data-list-input-container {
  border: 1px solid $grey1;
  border-radius: 3px;
  display: flex;

  .add-user-icon {
    color: $grey2;
    float: right;
    margin: auto;
  }
}
