$yellow: #f6bc26;
$red: #ed2c2a;
$green: #00cc66;
$green2: #e4f8e9;
$blue: #61c7fa;
$darkGrey: #3d5567;
$darkGrey2: #324655;
$grey1: #b1bbc2;
$grey2: #d8dde1;
$grey3: #eceef0;
$grey4: #6e7478;
$grey5: #f7f8fa;
$grey6: #c5ccd1;
$grey7: #e7eaec;
$grey8: #929898;
$grey9: #778894;
$primary: #003262;
$regularNeutrifPro: "Neutrif Pro";
$lightNeutrifPro: "Neutrif Pro Light";
$mediumNeutrifPro: "Neutrif Pro Medium";
$semiBoldNeutrifPro: "Neutrif Pro Semi Bold";
$boldNeutrifPro: "Neutrif Pro Bold";

@font-face {
  font-family: "Neutrif Pro";
  src: url("./../../assets/font/NeutrifPro/NeutrifPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Neutrif Pro Light";
  src: url("./../../assets/font/NeutrifPro/NeutrifPro-Light.otf") format("opentype");
}

@font-face {
  font-family: "Neutrif Pro Medium";
  src: url("./../../assets/font/NeutrifPro/NeutrifPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Neutrif Pro Semi Bold";
  src: url("./../../assets/font/NeutrifPro/NeutrifPro-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Neutrif Pro Bold";
  src: url("./../../assets/font/NeutrifPro/NeutrifPro-Bold.otf") format("opentype");
}
