@import "../../../../shared/util/variables";

.error-boundary-text {
  margin: 0;
  font-family: $boldNeutrifPro;
  font-size: calc(20px + 0.5vw);
  text-align: left;
  color: #3d5567;
  border: none;
  outline: none;
}
