@import "../../../util/variables.scss";

.datetime-builder {
  width: 15vw;

  .content {
    padding: 0.8em;
    background-color: white;
    border: 1px solid $grey2;
  }
}
