@import "../../../util/variables";

.warning-page {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  .tools-icon {
    color: #e60a69;
    font-size: 64px;
  }
}

.warning-title {
  color: $darkGrey;
  font-size: 30px;
  font-family: $boldNeutrifPro;
  margin: 5px;
  text-align: center;
}

.warning-text {
  color: $darkGrey;
  font-size: 12px;
  font-family: $regularNeutrifPro;
  margin: 25px;
  text-align: center;
}
