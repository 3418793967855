@import "../../../../shared/util/variables";

.catalog {
  width: 14vw;
  margin-bottom: 0.4em;

  .catalog-header {
    position: relative;
    color: $primary;
    font-family: $semiBoldNeutrifPro;
    font-size: calc(12px + 0.1vw);
    background-color: $grey5;
    border: 1px solid $grey2;
    border-radius: 3px;
    padding: 0.5em 1em 0.35em 1em;
    display: flex;
    cursor: pointer;

    .collapse-icon {
      font-size: 18px;
      color: $darkGrey;
      position: absolute;
      right: 0.7em;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .open-catalog {
    border-bottom: none;
  }

  .catalog-items {
    color: $grey1;
    justify-content: center;
    border: 0.1vw solid $grey2;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: none;
    overflow: none;
    overflow-y: auto;
    max-height: 283px;

    &::-webkit-scrollbar {
      width: 1ch;
    }

    &::-webkit-scrollbar-thumb {
      border-left: 2px solid transparent;
      border-right: 1px solid transparent;
      border-radius: 20px;
      background-clip: content-box;
      background-color: $grey2;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
