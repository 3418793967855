@import "../../../util/variables";

#IdPreference {
  padding: 2ch;
  padding-bottom: 0;
}

#inputIdPreference {
  border-radius: 3px;
  border: 1px solid $grey2;
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  font-size: 12px;
  outline: none;
  padding: 1.5ch;
  width: 88.5%;
}

::-webkit-input-placeholder {
  color: $grey6;
  font-family: $regularNeutrifPro;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $grey6;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $grey6;
}
