@import "../../../util/variables";

#yesNoPreference {
  padding: 2ch;
  padding-bottom: 0px;
}

.yesNoPreferenceBody {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  justify-content: start;
  column-gap: 2ch;

  .MuiButton-contained {
    background-color: $primary;
  }

  .MuiButton-contained:hover {
    background-color: #001a33;
  }

  .MuiButton-outlined {
    color: $primary;
    border: 1px solid $primary;
  }

  .MuiButton-outlined:hover {
    color: #001a33;
    border: 1px solid #001a33;
  }
}

.yesNoPreferenceButton {
  width: calc(70px + 1.5vw);
  padding: 0.8ch 0 0.8ch 0;
  font-family: $regularNeutrifPro;
  color: $darkGrey;
  font-size: 14px;
  border: 1px solid $grey2;
  border-radius: 3px;
  cursor: pointer;
}

.yesNoPreferenceButton:focus {
  outline: 0px;
}

.disable {
  color: $darkGrey;
  background-color: white;
}
