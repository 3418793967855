@import "../../../util/variables";

#Preference-Collumn {
  div {
    cursor: default;
  }
}

#PreferenceColumn-Body {
  padding-left: 0.8ch;
  padding-right: 2ch;
  padding-bottom: 2ch;
  border: 1px solid $grey2;
  border-radius: 3px;
  width: 18vw;

  section {
    padding-top: 0.7em;
  }
}

.select-field {
  padding-top: 2ch;
  padding-left: 0.8ch;
  text-align: center;
  color: $grey1;
  font-family: $lightNeutrifPro;
  font-size: calc(11px + 0.1vw);
  width: auto;
}
