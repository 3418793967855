@import "../../../util/variables";

.languages-column,
.notes {
  position: sticky;
  top: 10%;
}

.globalization-screen {
  display: flex;
  flex-direction: row;
  padding-right: 0.5%;
  margin-top: 2.5em;
  margin-left: 1.5em;
  position: relative;

  .languages-column {
    margin: 0;
    margin-right: 2ch;
    max-height: 60vh;

    .title {
      font-size: calc(16px + 0.1vw);
      margin-bottom: 1.5vh;
    }
  }

  .main {
    margin: 0;
    margin-top: 2.5%;
    width: 100%;
  }

  .notes {
    max-height: 60vh;
    margin: 0 1.2%;

    .title {
      font-size: calc(16px + 0.1vw);
      margin-bottom: 1.5vh;
    }

    .note-container {
      display: grid;
      grid-template-columns: 10% 90%;
      grid-template-rows: auto;
      grid-template-areas: "left right";
      border: 1px solid $grey2;
      border-radius: 3px;
      padding: 15px 15px 20px 15px;

      .note-icon {
        color: $blue;
        grid-area: left;
        font-size: 20px;
      }

      .note-message {
        grid-area: right;
        font-family: $regularNeutrifPro;
        font-size: 14px;
        line-height: 28px;
        color: $darkGrey;
        margin-top: -7px;
      }
    }
  }
}
