@import "../../../util/variables";

.icon-button-atom {
  border: none;
  background-color: transparent;
  font-family: $semiBoldNeutrifPro;
}

.icon-button-atom :hover {
  cursor: pointer;
}

.font-awesome-icon-button {
  padding: 0.5em;
}
