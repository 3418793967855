@import "../../../util/variables";

#create-form-button {
  border-radius: 2px;
  border: none;
  color: white;
  float: right;
  font-family: $boldNeutrifPro;
  font-size: 14px;
  margin-top: 15px;
  padding: 10px 27px;
  text-align: center;
  text-decoration: none;
}

#create-form-button-container {
  display: inline-block;
  width: 100%;
}
