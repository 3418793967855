@import "../../../../shared/util/variables";

.actions {
  align-items: center;
  color: $primary;
  display: flex;
  height: 100%;
  min-width: 300px;

  .material-icons {
    cursor: pointer;
  }
}

.actions-tools {
  align-items: center;
  background-color: white;
  border-radius: 3px;
  border: 1px solid $grey6;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 90%;

  .material-icons {
    color: $primary;
    cursor: pointer;
    font-size: 20px;
  }

  .separator-actions {
    border-left: 1px solid $grey1;
    height: 50%;
  }

  .clipboard-button {
    background-color: transparent;
    border: none;
    color: $primary;
    outline: none;
    padding: 0;
  }

  .clipboard-success {
    margin-left: -10%;
    margin-top: 2.5%;
    position: absolute;

    .triangle {
      border-bottom: 9px solid black;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      height: 0;
      margin-left: 40%;
      margin-top: -12%;
      position: absolute;
      width: 0;
    }

    .content {
      background-color: black;
      border-radius: 2.5px;
      border: solid 1px $grey1;
      color: white;
      font-family: $lightNeutrifPro;
      font-size: 10px;
      padding: 5px 10px;
      width: 4vw;
    }
  }
}

.menu-item:hover {
  .tooltip-action {
    opacity: 0.6;
    cursor: default;

    .tooltip-action-text {
      opacity: 1;
    }
  }
}

.tooltip-action {
  font-size: 12px;
  font-family: $regularNeutrifPro;
  color: white;
  background-color: black;
  text-align: center;
  padding: 0.15em 1em 0.2em 1em;
  border-radius: 2px;
  opacity: 0;
  position: absolute;
  margin-top: 1.2em;

  .tooltip-action-text {
    opacity: 0;
  }
}

.tooltip-action-builder {
  margin-left: -1.8em;
}

.tooltip-action-rules {
  margin-left: -1.5em;
}

.tooltip-action-users {
  margin-left: -1.5em;
}

.tooltip-action-language {
  margin-left: -2.3em;
}

.tooltip-action-powerbi {
  margin-left: -2em;
}

.tooltip-action-export {
  margin-left: -2em;
}

.tooltip-action-default {
  min-width: 60px;
  margin-left: -2.5em;
}
