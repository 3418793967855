@import "../../../util/variables";

.tooltip-header {
  background-color: black;
  border-radius: 2px;
  color: white;
  cursor: default;
  font-family: $regularNeutrifPro;
  font-size: 10px;
  margin-top: -0.2em;
  opacity: 0;
  padding: 0.2em 1em;
  position: absolute;
  text-align: center;
}

.tooltip-header-builder {
  margin-left: -1.4em;
}

.tooltip-header-rules {
  margin-left: -0.7em;
}

.tooltip-header-language {
  margin-left: -1.5em;
}

.tooltip-header-users {
  margin-left: -0.7em;
}

.item:hover {
  .tooltip-header {
    opacity: 0.6;
  }
}
