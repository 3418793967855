@import "../../../util/variables";

.secondary-menu {
  display: flex;
  margin: 0;
  margin-top: -0.4em;
  padding: 0;

  .item {
    padding: 0 10px;
    color: $grey1;
    cursor: pointer;
  }

  .item.selected {
    color: $red;
  }
}
