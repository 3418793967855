@import "../../../util/variables";

#fixed-title-preference {
  padding: 2ch;
  padding-bottom: 0;
}

#fixed-title-container {
  border: 1px solid $grey2;
  padding: 1.5ch;
  color: $grey1;
  background-color: $grey5;
  font-family: $regularNeutrifPro;
  border-radius: 3px;
  width: 88.5%;
  font-size: calc(12px + 0.1vw);
  cursor: default;
  user-select: none;
  outline: none;
}

::-webkit-input-placeholder {
  color: $grey2;
  font-family: $regularNeutrifPro;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $darkGrey;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $darkGrey;
}
