@import "./shared/util/variables";

body {
  margin: 0;
  overflow: hidden;
  p {
    cursor: default;
  }

  h1 {
    cursor: default;
  }

  h2 {
    cursor: default;
  }
}

.invisible {
  display: none !important;
}
