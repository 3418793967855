@import "../../../util/variables";

.equipment-mrk-preference {
  padding: 2ch;
  padding-bottom: 0;
  width: 80%;

  .options {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: start;
    column-gap: 5ch;

    .content {
      width: 6vw;
      border-radius: 3px;
      height: 15px;
      outline: none;
      color: $darkGrey;
      font-family: $regularNeutrifPro;
      padding: 15%;
      align-items: center;
      justify-content: center;
    }
  }
}
