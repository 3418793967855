@import "../../../util/variables.scss";

.input-textarea-builder {
  color: $darkGrey;

  .content {
    padding: 1em 0.8em;
    width: 50%;
    display: block;
    cursor: text;
    resize: none;
    background-color: white;
    border: 1px solid $grey2;

    &:hover {
      cursor: text;
    }

    &:focus {
      outline: none;
    }
  }
}
