@import "../../../util/variables";

.list-items-preference-dropdown {
  padding: 2ch 2ch 0;
  width: 82%;
  text-align: right;

  .content {
    border-radius: 3px;
    border: 1px solid $grey2;
    color: $darkGrey;
    font-family: $regularNeutrifPro;
    font-size: 12px;
    height: 50px;
    outline: none;
    padding: 1ch 1.4ch;
    resize: none;
    width: 95%;
  }

  .addOptions-button {
    display: flex;
    flex-direction: row-reverse;

    button {
      border-radius: 3px;
      cursor: pointer;
      font-family: $regularNeutrifPro;
      font-size: 14px;
      margin-top: 5px;
      padding: 1ch 2ch 1ch 2.5ch;
    }
  }
}

#dropdown-options-modal {
  #modal-title {
    color: $primary;
    font-family: $boldNeutrifPro;
    font-size: 16px;
  }

  #modal-content {
    border-radius: 3px;
    color: $darkGrey;
    font-family: $regularNeutrifPro;
    height: 50px;
    outline: none;
    padding: 0 24px;
    resize: none;

    .messageModal {
      border-radius: 3px;
      font-size: calc(12px + 0.1vw);
      font-weight: bold;
      margin-bottom: 10px;
      text-align: start;

      .delete-option h6 {
        font-family: $regularNeutrifPro;
        font-size: 12px;
      }

      span {
        font-size: 14px;
      }

      .material-icons {
        font-size: 20px;
      }

      #response {
        border-radius: 4px;
        border: 1px solid $grey2;
        height: auto;
        max-height: 300px;
        min-height: 300px;
        overflow: auto;
        padding: 1ch 1.4ch;
        scrollbar-face-color: $darkGrey;
        scrollbar-track-color: $grey2;

        &::-webkit-scrollbar {
          width: 1ch;
        }

        &::-webkit-scrollbar-thumb {
          border-left: 2px solid transparent;
          border-right: 1px solid transparent;
          border-radius: 20px;
          background-clip: content-box;
          background-color: $grey2;
        }

        &::-webkit-scrollbar-track {
          background: white;
        }
      }

      .warning {
        color: $red;
        display: block;
        font-family: $regularNeutrifPro;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: left;
      }
    }

    #modal-footer {
      display: block;
      margin-bottom: 10px;
    }
  }

  [class^="inputResponse"],
  [class^="inputWeight"] {
    border-color: $grey1;
    border-radius: 3px;
    border: 1px solid $grey2;
    box-sizing: border-box;
    color: $darkGrey;
    font-family: $regularNeutrifPro;
    font-size: 12px;
    outline: none;
    padding: 1.5ch 1ch;
    width: 95%;
  }

  .delete {
    text-align: center;
  }

  .delete button {
    color: $red;
    font-family: $regularNeutrifPro;
    padding: 1.5ch;
  }

  .tableRow {
    margin-top: 0.5ch;
  }
}

::-webkit-input-placeholder {
  color: $darkGrey;
  font-family: $regularNeutrifPro;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $darkGrey;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $darkGrey;
}
