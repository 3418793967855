@import "../../../util/variables.scss";

.radio-button-builder {
  .content {
    border: none;
    background-color: transparent;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: start;
    column-gap: 1.5ch;

    .item {
      background-color: white;
      border-radius: 3px;
      border: 1px solid $grey2;
      display: flex;
      height: 5vh;
      outline: none;
      width: 8vw;

      .text,
      .text:focus {
        align-self: center;
        border: none;
        color: $darkGrey;
        display: block;
        font-family: $regularNeutrifPro;
        font-size: 12px;
        max-height: 2.5vh;
        max-width: 7vw;
        outline: none;
        overflow: hidden;
        padding-left: 1ch;
        text-overflow: ellipsis;
      }
    }
  }
}
