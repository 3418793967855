@import "../../../util/variables";

#photos-number-preference {
  padding: 30px 20px 0 16px;

  .photos-number-preference-title {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;

    div {
      padding-top: 5px;
    }
  }

  .photos-number-preference-body {
    display: flex;
    justify-content: flex-start;
    padding-left: 5px;
  }
}

.info {
  color: $blue;
}

.menu-item:hover {
  .tooltip-action {
    z-index: 9999999999;
    opacity: 0.8;
    cursor: default;

    .tooltip-action-text {
      opacity: 1;
    }
  }
}

.tooltip-action {
  font-size: 12px;
  font-family: $regularNeutrifPro;
  color: white;
  background-color: black;
  text-align: center;
  padding: 0.15em 1em 0.2em 1em;
  border-radius: 2px;
  opacity: 0;
  position: absolute;
  margin-top: 0.6em;

  .tooltip-action-text {
    opacity: 0;
  }
}