@import "../../../util/variables";

.catalog-column,
.preferences {
  position: sticky;
  top: 10%;
}

.body-builder-screen {
  display: flex;
  flex-direction: row;
  padding-right: 0.5%;
  margin-top: 2.5em;
  margin-left: 1.5em;
  position: relative;

  .catalog-column {
    margin: 0;
    margin-right: 2ch;
    max-height: 92vh;

    .title {
      font-size: calc(16px + 0.1vw);
      margin-bottom: 1.5vh;
    }
  }

  .main {
    margin: 0;
    margin-top: 2.5%;
    width: 59.5%;
  }

  .preferences {
    margin: 0;
    max-height: 92vh;
    margin-left: 1.2%;

    overflow-y: auto;

    .title {
      font-size: calc(16px + 0.1vw);
      margin-bottom: 1.5vh;
    }

    .save {
      position: fixed;
      bottom: 12%;
      right: 2.5%;
      height: 5%;
      width: 14%;
    }
  }
}
