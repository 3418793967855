@import "../../../util/variables";

.text-icon-button {
  background-color: transparent;
  align-self: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  height: auto;
  width: auto;
  cursor: pointer;
  outline: none;

  .text {
    margin-right: 8px;
    font-size: 14px;
    font-family: $boldNeutrifPro;
  }
}
