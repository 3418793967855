@import "../../../util/variables";

.form-user-association-item {
  border-radius: 3px;
  border: 1px solid $grey2;
  display: inline-block;
  margin: 0 0.6em 0.6em 0;
  padding: 0.4em 0 0.6em 0.8em;

  .form-user-association-item-title {
    color: $darkGrey;
    font-size: 12px;
  }

  .font-awesome-icon-button {
    color: $red;
    cursor: pointer;
    float: right;
    font-size: medium;
    margin: 0;
    margin-top: 0.1em;
    padding: 0 0.5em;
  }
}
