@import "../../../util/variables";

.data-list {
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: none;
  list-style: none;
  margin: 0 auto;
  max-height: 200px;
  overflow: scroll;
  padding: 0px;
  position: absolute;
  width: 98.6%;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 1ch;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 2px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
    background-color: $grey2;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
}
