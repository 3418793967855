@import "../../../util/variables";

.header-builder-screen {
  min-width: 90vw;
  margin: 0 2% 0px 1.5%;
  padding-top: 1ch;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.1px solid $grey3;

  .sub-menu {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    .form-info-and-header-menu {
      display: flex;
      flex-direction: column;
      height: auto;
      margin-top: 1ch;
    }

    .form-name-and-header-menu {
      display: flex;
      flex-direction: row;
    }
    .menu {
      margin-top: auto;
      margin-bottom: auto;
      border-bottom: none;

      .secondary-menu {
        margin-top: 8px;
      }

      .material-icons {
        font-size: 20px;
      }
    }
  }

  .action-buttons {
    margin-left: auto;

    .button {
      color: white;
      background-color: $red;
      font-family: $boldNeutrifPro;
      font-size: 14px;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      outline: none;
      margin-bottom: 1ch;
      position: absolute;
    }

    .continue-button {
      right: 2%;
      font-size: 14px;
      font-family: $boldNeutrifPro;

      .text-icon-button {
        padding: 1ch 2ch 1ch 2.5ch;
      }
    }

    .save-button {
      right: 13.5%;
      margin: 0px;
      padding: 0px;
      padding-left: 7px;
      margin-left: 2px;
      font-size: 14px;
      font-family: $boldNeutrifPro;

      .text-icon-button {
        padding: 1ch 1ch;
      }
    }

    .text-icon-button {
      color: white;
      background-color: $red;
      font-family: $boldNeutrifPro;
      font-size: 14px;
    }
  }
}
.form-satuts {
  margin-top: 20px;
}

.dashed-underline-text {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;
}
