@import "../../../util/variables";

#new-form-link {
  text-decoration: none;
}

#new-form-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#button-new-form {
  display: inline-flex;
  border: none;
  border-radius: 2px;
  padding: 10px 24px;
  font-family: $semiBoldNeutrifPro;
  background: none;
  color: white;
  text-decoration: none;
  font-size: 15px;
  width: 100%;
  background-color: $green;
  text-align: center;
}

#text-content-container {
  margin-top: 2px;
  width: 100%;
}
