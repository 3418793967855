@import "../../../shared/util/variables";

.globalization-form-container {
  border-radius: 4px;
  border: 1px solid $grey1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5%;
  padding: 16px;
  position: relative;

  .globalization-form-title {
    color: $primary;
    font-family: $regularNeutrifPro;
    font-size: calc(18px + 0.1vw);
    font-weight: bold;
  }

  .globalization-form-title-edit {
    margin: 0 0 8px 0;
  }

  .globalization-form-title-input {
    background-color: white;
    border-radius: 2px;
    border: 1px solid $grey1;
    color: $grey4;
    font-family: $regularNeutrifPro;
    font-size: 14px;
    outline: none;
    padding: 0.8em;
    width: 80%;
  }
}

.globalization-section-list-item {
  cursor: pointer;
  margin-bottom: 2.5%;
  position: relative;
  border-radius: 4px;
  border: 1px solid $grey7;

  .globalization-fields {
    padding: 0.5em;
    transition: 0.4s;
    margin: 0.5em 1.5em;
  }

  .globalization-closed-section {
    border-bottom: none;
  }

  .globalization-section-header {
    border: none;
    background-color: $grey5;
    border-bottom: 2px solid $grey7;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0;

    .globalization-header-container {
      display: flex;
      flex-direction: column;
      padding: 8px 0 8px 16px;
      width: 40%;

      .globalization-header-title {
        background-color: transparent;
        color: $darkGrey;
        font-family: $regularNeutrifPro;
        font-size: calc(14px + 0.1vw);
        text-align: left;
        border: none;
        outline: none;
        width: 82%;
        text-overflow: ellipsis;
      }
      .globalization-header-title-edit {
        padding: 0 0 8px 0;
      }

      .globalization-header-input {
        background-color: white;
        border-radius: 2px;
        border: 1px solid $grey1;
        color: $grey4;
        font-family: $regularNeutrifPro;
        outline: none;
        padding: 0.8em;
        width: 80%;
      }
    }

    .globalization-edit-open-close-button {
      background-color: $grey5;
      border-right: 2px solid $grey7;
      margin: 0.2em 0;
      text-align: center;
      width: 4%;
    }

    .globalization-open-close-button {
      background-color: $grey5;
      border-right: 2px solid $grey7;
      margin: 0.2em 0;
      text-align: center;
      width: 4%;
    }

    .globalization-input-title {
      background-color: transparent;
      color: $darkGrey;
      font-family: $regularNeutrifPro;
      font-size: calc(14px + 0.1vw);
      text-align: left;
      border: none;
      outline: none;
      width: 82%;
      margin: 0 1.5% 0 2%;
      text-overflow: ellipsis;
    }
  }

  .globalization-section-icon-button {
    color: $darkGrey;
    background-color: transparent;
    border: none;
    box-decoration-break: none;
    outline-style: none;
    cursor: pointer;
  }

  .globalization-section-message {
    border: 1px dashed $grey2;
    color: $grey1;
    font-family: $lightNeutrifPro;
    font-size: 12px;
    margin-top: 1.5ch;
    padding: 0;
    text-align: center;
    width: auto;
  }

  .globalization-section-input-title:hover {
    cursor: pointer;
  }

  .globalization-field-list-container {
    padding: 24px;

    .globalization-field-container {
      justify-content: space-between;
      .globalization-default-title {
        font-size: calc(14px + 0.1vw);
        color: $darkGrey;
        font-family: $regularNeutrifPro;
      }

      .globalization-default-description {
        font-size: 14px;
        color: $grey8;
        font-weight: lighter;
        font-family: $regularNeutrifPro;
      }

      .globalization-default-answer {
        font-size: calc(12px + 0.1vw);
        color: $darkGrey;
        font-family: $regularNeutrifPro;
        font-weight: bold;
        padding: 0 0 0 16px;
      }

      display: flex;
      flex-direction: column;
      padding: 0 0 16px 0;

      .globalization-field-title {
        font-size: calc(14px + 0.1vw);
        color: $darkGrey;
        font-family: $regularNeutrifPro;
        font-weight: 400;
        padding: 0 0 14px 0;
      }
      
      .globalization-field-description {
        font-size: 14px;
        color: $grey8;
        font-family: $regularNeutrifPro;
        display: inline-block;
        font-weight: 400;
        margin: 0 0 0 16px;
      }

      .globalization-field-input {
        background-color: white;
        border-radius: 2px;
        border: 1px solid $grey1;
        color: $grey4;
        font-family: $regularNeutrifPro;
        margin: 10px 0 10px 16px;
        outline: none;
        padding: 0.8em;
        width: 80%;
        &::placeholder{
          color: $grey4;
        }
      }

      .globalization-field-option-container {
        display: flex;
        flex-direction: column;
        padding: 16px 0 0 0;

        .globalization-field-option-title {
          font-size: calc(14px + 0.1vw);
          color: $darkGrey;
          font-family: $regularNeutrifPro;
          font-weight: bold;
          padding: 0 0 14px 16px;
        }

        .globalization-field-option-input {
          background-color: white;
          border-radius: 2px;
          border: 1px solid $grey1;
          color: $grey4;
          font-family: $regularNeutrifPro;
          margin: 0 0 0 16px;
          outline: none;
          padding: 0.8em;
          width: 40%;
        }
      }
    }
  }
}
