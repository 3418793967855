@import "../../../util/variables";

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  background-color: white;
  border-radius: 3px;
  border: 1px solid $grey1;
  box-sizing: border-box;
  cursor: default;
  font-size: calc(12px + 0.1vw);
  outline: none;
  overflow: hidden;
  padding: 8px 8px 8px 10px;
  position: relative;
  width: 115%;
}

.Dropdown-menu {
  background-color: white;
  border-radius: 3px;
  border: 1px solid $grey1;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  width: 115%;
  z-index: 1000;
  color: $darkGrey;
  font-family: $regularNeutrifPro;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: $darkGrey;
  cursor: pointer;
  display: block;
  font-size: 12px;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: $grey3;
  color: $darkGrey;
}

.Dropdown-option.is-selected {
  background-color: $grey2;
  color: $darkGrey;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.Dropdown-placeholder {
  color: $darkGrey;
  display: inline-flex;
  font-family: $regularNeutrifPro;
  font-size: 12px;
  margin-right: 0.5em;
}

.Dropdown-arrow-wrapper {
  display: inline-flex;
  float: right;
}

.open-close-arrow {
  color: $grey1;
}
