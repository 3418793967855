@import "../../../../shared/util/variables";

#typePreferenceTitle {
  padding: 2ch;
  padding-bottom: 0px;
}
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.basic-multi-select {
  margin: 6px 0 0 0;
  color: #3d5567;
  font-family: $regularNeutrifPro;
  font-size: 14px;
  width: 88.5%;
}
.select__control {
  border-color: #d8dde1;
  border-radius: 3px !important;
}

.select__indicator-separator {
  display: none !important;
}

.select__menu {
  font-family: $regularNeutrifPro !important;
  padding: 0 !important;
  margin: 1px 0 0 0 !important;
}

.select__indicators .select__dropdown-indicator {
  color: #3d5567 !important;
}
