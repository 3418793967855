@import "../../../../shared/util/variables";

.new-form-page {
  align-items: center;
  color: $primary;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .container {
    margin-top: 7.5%;
    margin-left: 25%;
    width: 50%;
    height: 80%;

    .newForm {
      margin-bottom: 5em;
    }

    .title {
      font-family: $boldNeutrifPro;
      font-size: calc(24px + 0.1vw);
    }

    .data-content {
      background-color: white;
      border-radius: 3px;
      border: 1px solid $grey2;
      color: $darkGrey;
      font-family: $regularNeutrifPro;
      font-size: calc(12px + 0.1vw);
      padding: 3.5%;

      .label-title {
        font-family: $semiBoldNeutrifPro;
        margin-bottom: 0.5em;
        display: block;
      }

      .form-element {
        margin: 0;
        border: 1px solid $grey2;
        border-radius: 3px;
        padding: 0.7em 1em 0.7em 0.1em;
        width: 96%;
        outline: none;
        margin-bottom: 15px;
        font-family: $regularNeutrifPro;
        font-size: calc(12px + 0.1vw);
        color: $darkGrey;
        padding-left: 0.6em;
      }

      .dropdown-languages {
        width: 38%;
        appearance: none;
        padding: 0;
        border: none;
        margin-bottom: 15px;
        outline: none;
        font-family: $regularNeutrifPro;
        font-size: calc(12px + 0.1vw);
        color: $darkGrey;
      }

      .dropdown-languages-icon {
        color: $grey1;
      }
    }

    .upload-button-container {
      display: inline-block;
      width: 100%;
    }

    .upload-button {
      background-color: #00cc66;
      border: none;
      border-radius: 2px;
      color: white;
      padding: 10px 27px;
      font-family: $boldNeutrifPro;
      text-align: center;
      text-decoration: none;
      font-size: 14px;
      float: right;
    }
  }
}
