@import "../../../shared/util/variables";

.rule-container {
  padding: 49px 24px;
}

.no-rule-title {
  color: #003262;
  font-family: $boldNeutrifPro;
  font-size: 16px;
}

.no-rule-message {
  max-width: 74.7%;
  color: $grey4;
  border: 1px solid $grey2;
  margin-bottom: 1%;
  p {
    font-family: $lightNeutrifPro;
    font-size: 16px;
  }
}

.rule-section-list-item {
  cursor: pointer;
  margin-bottom: 2.5%;
  position: relative;
  border-radius: 4px;
  border: 1px solid $grey2;
  width: 74.7%;

  .rule-section-header {
    border: none;
    background-color: $grey5;
    border-bottom: 2px solid $grey7;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0;

    .rule-header-actions {
      width: 10%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .rule-trash-icon {
        margin: 0 0 0 15px;
      }
    }

    .move-button {
      margin-right: 15px!important;
    }

    .rule-header-container {
      display: flex;
      flex-direction: column;
      padding: 8px 0 8px 16px;
      width: 40%;

      .rule-header-title {
        background-color: transparent;
        color: $darkGrey;
        font-family: $regularNeutrifPro;
        font-size: calc(14px + 0.1vw);
        text-align: left;
        border: none;
        outline: none;
        width: 82%;
        text-overflow: ellipsis;
      }
    }

    .rule-open-close-button {
      background-color: $grey5;
      border-right: 1px solid $grey7;
      font-size: 1.5em;
      margin: 0.2em 0;
      text-align: center;
      width: 4%;
    }
  }

  .rule-section-icon-button {
    border: none;
    box-decoration-break: none;
    color: $darkGrey;
    cursor: pointer;
    outline-style: none;
    background-color: transparent;
    font-size: 20px;
  }

  .input-title {
    background-color: transparent;
    border: none;
    color: $darkGrey;
    font-family: $regularNeutrifPro;
    font-size: 16px;
    margin: 0 1.5% 0 2%;
    outline: none;
    text-align: left;
    text-overflow: ellipsis;
    width: 82%;
  }

  .rule-content-container {
    padding: 21.5px;

    .rule-grid-container {
      display: grid;
      grid-template-columns: 0.3fr 0.3fr 0.35fr;
      grid-template-rows: 0.5fr 0.5fr;
      gap: 16px 55px;
      grid-template-areas:
        "if status response"
        "action question options";
    }
    .response {
      grid-area: response;
    }
    .options {
      grid-area: options;
    }
    .question {
      grid-area: question;
    }
    .action {
      grid-area: action;
    }
    .status {
      grid-area: status;
    }
    .if {
      grid-area: if;
    }

    .rule-field-label {
      height: 14px;
      width: 9px;
      color: #3d5567;
      font-family: $regularNeutrifPro;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }
    .rule-header-input {
      appearance: none;
      background-color: white;
      border-radius: 2px;
      border: 1px solid $grey6;
      color: #3d5567;
      font-family: $regularNeutrifPro;
      font-size: 14px;
      margin: 6px 0 0 0;
      outline: none;
      padding: 0.69em;
      width: 100%;
    }

    .basic-multi-select {
      margin: 6px 0 0 0;
      width: 115%;
      color: #3d5567;
      font-family: $regularNeutrifPro;
      font-size: 14px;
    }
  }
}

.rule-dropdown {
  appearance: none;
  border: none;
  color: $grey1;
  font-family: $regularNeutrifPro;
  font-size: calc(12px + 0.1vw);
  margin-bottom: 15px;
  outline: none;
  padding: 6px 0 0 0;
}

.rule-dropdown-control {
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select__control {
  border-color: #d8dde1 !important;
  border-radius: 3px !important;
}

.select__indicator-separator {
  display: none !important;
}

.select__menu {
  font-family: $regularNeutrifPro !important;
  padding: 0 !important;
  margin: 1px 0 0 0 !important;
}

.select__indicators .select__dropdown-indicator {
  color: #3d5567 !important;
}
