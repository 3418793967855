@import "../../../util/variables";

.section-list-item {
  position: relative;
  margin-bottom: 2.5%;
  cursor: pointer;

  .selected-style {
    .body-content {
      border: 1px solid $grey2;
      border-radius: 4px;

      .section-header {
        .input-title {
          font-size: 16px;
          color: $darkGrey;
        }
      }

      .fields {
        padding-top: 1ch;
      }
    }

    .closed-section {
      border-bottom: none;
    }

    .input-title {
      width: 82%;
    }
  }

  .unselected-style {
    border: 1px solid $grey2;
    border-radius: 4px;
    margin-bottom: 2ch;
    opacity: 0.4;

    .body-content {
      .section-header {
        .input-title {
          color: $darkGrey;
        }
      }

      .fields {
        padding-top: 0.5ch;
      }
    }

    background-color: white;
    width: 100%;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    .input-title {
      width: 96%;
    }
  }

  .body-content {
    .section-header {
      border: none;
      background-color: $grey5;
      border-bottom: 1px solid $grey2;
      display: flex;
      height: 2.05em;
      text-align: left;
      margin: 0;

      .open-close-button {
        width: 4%;
        background-color: $grey5;
        border-right: 1px solid $grey2;
        margin: auto;
        text-align: center;
      }

      .input-title {
        background-color: transparent;
        font-family: $regularNeutrifPro;
        font-size: 16px;
        text-align: left;
        border: none;
        outline: none;
        margin: 0 1.5% 0 2%;
        text-overflow: ellipsis;
      }
    }

    .fields {
      padding: 0.5em;
      transition: 0.4s;
      margin: 0.5em 1.5em;
    }
  }

  .section-icon-button {
    color: $darkGrey;
    background-color: transparent;
    border: none;
    box-decoration-break: none;
    outline-style: none;
    cursor: pointer;
    margin-left: 0.2em;
    font-size: 16px;
  }

  .trash-button {
    color: $red;
    cursor: pointer;
  }

  .section-message {
    text-align: center;
    border: 1px dashed $grey2;
    padding: 0;
    margin-top: 1.5ch;
    color: $grey1;
    font-family: $lightNeutrifPro;
    font-size: 12px;
    width: auto;
  }

  .section-input-title:hover {
    cursor: pointer;
  }

  .move-buttons {
    width: 14%;
    text-align: right;
    margin: 0 2% 0 0;
    align-self: center;

    .material-icons {
      font-size: 18px;
    }
  }
}
