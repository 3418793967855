@import "../../../util/variables";

.TitleColumn {
  color: $darkGrey;
  font-family: $semiBoldNeutrifPro;
  font-size: 14px;
  margin: 0.5ch 0;
  padding: 0;
  text-align: left;
}
