@import "../../../util/variables.scss";

.saved-container {
  width: fit-content;

  .material-icons {
    color: $green;
    font-size: 14px;
  }

  .saved {
    color: $green;
    font-family: $regularNeutrifPro;
    font-size: 12px;
    margin-left: 0.4em;
  }
}

.unsaved-container {
  .unsaved {
    color: $primary;
    font-family: $regularNeutrifPro;
    font-size: calc(6px + 0.5vw);
  }
}
