@import "../../../util/variables";

.data-list-item {
  padding: 10px 0 10px 10px;
  font-family: $regularNeutrifPro;
  cursor: pointer;
  border: none;
}

.data-list-item:hover {
  background-color: $grey5;
}
