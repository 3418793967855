@import "../../../util/variables.scss";

.input-text-field .MuiOutlinedInput-root {
  padding: 1.5ch;
  border-radius: 3px;
  outline: none;
}

.input-text-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid $grey2 !important;
}

.input-text-field .MuiInputBase-input {
  color: $darkGrey;
}

.input-text-field .MuiOutlinedInput-inputMarginDense {
  padding: 0px 0px;
}

.input-text-field .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid $red !important;
}