@import "../../../../shared/util/variables";

.languages {
  width: 14vw;
  margin-bottom: 0.4em;
  color: $grey1;
  justify-content: center;
  border: 0.1vw solid $grey2;
  border-radius: 3px;

  .languages-header {
    color: $grey2;
    cursor: pointer;
    font-family: $regularNeutrifPro;
    font-size: 12px;
    padding: 12px;

    p {
      margin: 0;
      padding: 0;
    }
  }
}
