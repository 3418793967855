@import "../../../util/variables.scss";

.form-name-input {
  border: none;
  color: $primary;
  font-family: $boldNeutrifPro;
  padding: 1px 0;
  font-size: 28px;
  margin: 0;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
}
