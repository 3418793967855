@import "../../../util/variables.scss";

.dropdown-builder {
  .content {
    width: 30%;
    background-color: white;
    border: 1px solid $grey2;

    .field {
      align-items: center;
      height: 6vh;
      padding: 0 1ch;
      width: 100%;

      p {
        color: $darkGrey;
        display: inline-block;
        font-size: 12px;
        max-width: 10vw;
        overflow: hidden;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon {
        color: $grey1;
        float: right;
        padding: 0.35em 1em 0 0;
      }
    }

    .options {
      display: none;
      max-height: 60px;
      width: 100%;
      overflow: auto;
      padding-top: 0.7ch;
      padding-bottom: 0.5ch;
      border-top: none;
      scrollbar-face-color: $darkGrey;
      scrollbar-track-color: $grey2;

      .option {
        display: block;
        color: $darkGrey;
        width: auto;
        padding-left: 1.2ch;

        p {
          text-align: start;
          margin: 0;
        }
      }

      &::-webkit-scrollbar {
        width: 1ch;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border: 2.5px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2.5px solid transparent;
        border-radius: 10px;
        background-clip: content-box;
        background-color: $grey2;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }
    }

    .options.empty {
      display: none;
      border-top: none;
    }

    &:focus {
      outline: none;
    }
  }

  select:hover {
    cursor: pointer;
  }
}

.selected-border {
  .dropdown-builder {
    .content {
      .field {
        p {
          display: none;
          margin-top: 0;
        }

        .material-icons {
          padding: 0.35em 1em 0 0;
          text-align: end;
        }
      }

      .options {
        display: block;
        border-top: 1px solid $grey2;
        font-size: calc(10px + 0.1vw);
      }

      .options.empty {
        display: none;
        border-top: none;
      }
    }
  }
}
