@import "../../../../shared/util/variables";

.catalog-list-item {
  height: 100%;
  width: 100%;
  border: none;
  border-top: 0.1vw solid $grey2;
  background-color: transparent;
  font-family: $semiBoldNeutrifPro;
  color: $darkGrey;
  padding: 0;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  outline-color: transparent;
  cursor: pointer;
  max-height: 35px;

  .item {
    display: grid;
    grid-template-columns: 23% 77%;
    grid-template-areas: "icon text";
    align-items: center;
    width: 11vw;
    cursor: pointer;

    .item-icon {
      font-size: 14px;
      padding: 0 0.7em;
      grid-area: icon;
      color: $primary;
    }

    .name {
      color: $darkGrey;
      grid-area: text;
      font-family: $regularNeutrifPro;
      font-size: calc(10px + 0.1vw);
      text-align: left;
      cursor: pointer;
    }
  }

  .add-icon {
    font-size: 16px;
    padding: 0 0.7em;
    color: $grey2;
    order: 0;
    cursor: pointer;
  }

  ::selection {
    background-color: transparent;
  }
}
