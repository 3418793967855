@import "../../../util/variables.scss";

.unique-photo-builder {
  width: 100%;

  .content {
    width: 100px;
    height: 100px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    border: 1px solid $grey2;
  }

  .font-awesome-icon-button {
    color: $grey2;
    background-color: white;
  }
}
