@import "../../../util/variables";

.containerChecklistValuePreference {
  padding: 2ch;
  padding-bottom: 0;

  > input {
    border: 1px solid $grey2;
    padding: 1.5ch;
    color: $darkGrey;
    font-family: $regularNeutrifPro;
    border-radius: 3px;
    width: 88.5%;
    outline: none;
  }
}
