@import "../../../util/variables";

.users-association {
  min-height: 64vh;
  padding: 2.5%;
  font-family: $semiBoldNeutrifPro;
  color: $darkGrey;
  font-size: calc(12px + 0.1vw);

  .container {
    display: flex;
    align-items: start;
    background-color: white;
    border: 1px solid $grey2;
    border-radius: 3px;
    padding: 3%;
    margin-bottom: 24px;

    .label-title {
      color: $primary;
      font-size: 14px;
    }

    .email-input-label {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
}
