@import "../../../util/variables";

#modal-container .invisible {
  display: none;
}

.list-items-preference-dropdown .modal-container,
.list-items-preference-radio .modal-container {
  textarea {
    resize: none;
  }

  #response {
    max-height: 350px;
    min-height: 300px;
    border: 1px solid $grey2;
    margin: 20px 0;
    overflow: auto;
    padding: 5px 15px;
  }

  .tableHeader {
    font-family: $semiBoldNeutrifPro;
    font-size: calc(12px + 0.1vw);
    color: $darkGrey;
    text-align: left;
    padding: 0;
    margin: 0.5ch 0;
  }

  .tableRow {
    margin-bottom: 0.5ch;
  }

  .tableFoot {
    margin-top: 5px;

    .TitleColumn {
      float: left;
    }

    .switch {
      margin: 0.5ch 0.5ch;
    }
  }
}
