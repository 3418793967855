@import "../../../util/variables";

.add-section-button {
  background-color: $darkGrey;
  border: none;
  border-radius: 2.5px;
  padding: 0.6em 0.35em 0.6em 0.9em;
  margin: 0.2em 0;
  box-decoration-break: none;
  outline-style: none;
  cursor: pointer;

  p {
    cursor: pointer;
    color: white;
    font-family: $boldNeutrifPro;
    font-size: 14px;
  }

  .add-section-icon {
    color: white;
  }
}
