@import "../../../../shared/util/variables";

.alert {
  padding: 8px 16px 8px 16px;
  background: #fafbd7;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* app_state/notif/yellow_2 */
  text-align: center;
  border: 1px solid #e7ea2e;
  border-radius: 3px;

  font-family: "Neutrif Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  /* identical to box height */

  /* app_state/notif/yellow_4 */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  color: #727400;
}

.error{
  color: red;
  font-family: $mediumNeutrifPro;
  text-align: start;
  width: 88.6%;
  font-size: 14px;
}
