@import "../../../util/variables";

.rhi-button {
  color: white;
  background-color: $darkGrey;
  font-family: $boldNeutrifPro;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  padding: 0.8em 1.5em;
  cursor: pointer;
  outline: none;
}
