@import "../../../util/variables";

.field-item-container {
  position: relative;

  .main-fields-articles {
    margin-top: 2ch;
  }

  .main-fields-articles:hover {
    cursor: pointer;
  }

  .field-component {
    cursor: pointer;
  }

  .field-trash {
    margin-left: 15px;
  }

  .toolbox-field {
    position: absolute;
    top: -0.9em;
    right: 0;
    margin: 0;
    background-color: white;
    border: 1px solid $grey2;
    border-radius: 3px;
    padding: 4px 10px 4px 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    div {
      cursor: pointer;
      display: inline-block;
    }
  }

  .move-fields-icon {
    color: $darkGrey;
    font-size: 16px;
    outline: none;
    padding: 2px;
  }

  .font-awesome-icon-button {
    padding: 0;
    padding-top: 2.5px;
    padding-right: 2px;
  }

  .selected-border {
    background-color: #f9f9f9;
    border: 1px solid $grey2;
    border-radius: 3px;
    width: auto;
    height: 100%;
    margin-top: 10px;
  }

  input {
    color: $darkGrey;
  }

  .separator-fields {
    border-left: 1px solid $grey2;
    margin-right: 10px;
    margin-left: 7.5px;
    position: relative;
  }

  .trash-field-icon {
    color: $red;
    font-size: 18px;
    margin: auto;
  }

  .field-item {
    margin-left: 0.6em;
    padding: 0;
    padding-bottom: 0.75em;

    .title {
      color: $darkGrey;
      font-family: $regularNeutrifPro;
      font-size: 14px;
      margin-bottom: 0.3em;
      width: 85%;

      .mandatory-signal {
        font-size: calc(18px + 0.2vw);
        color: $red;
      }
    }

    .content {
      border-radius: 3px;
      color: $grey1;
      font-family: $regularNeutrifPro;
      font-size: 12px;
      margin: 0;
      outline: none;
      text-align: left;
    }
  }
}
