@import "../../../shared/util/variables";

.confirmation-modal-icon {
  padding-right: 8px;

  &.success {
    color: $green;
  }

  &.error {
    color: $red;
  }
}

.modal-header {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  max-height: 30px;
}
