@import "../../../util/variables";

.fixed-list-items-preference {
  padding: 2ch;
  padding-bottom: 0;
  width: 80%;

  .fixed-content {
    background-color: $grey5;
    border-radius: 3px;
    border: 1px solid $grey2;
    color: $darkGrey;
    cursor: default;
    font-family: $regularNeutrifPro;
    font-size: 12px;
    height: 50px;
    outline: none;
    padding: 1ch 1.4ch;
    resize: none;
    user-select: none;
    width: 100%;
    scrollbar-face-color: $darkGrey;
    scrollbar-track-color: $grey2;

    &::placeholder {
      color: $grey1;
    }

    &::-webkit-scrollbar {
      width: 0.7ch;
    }

    &::-webkit-scrollbar-thumb {
      border-left: 2px solid transparent;
      border-right: 1px solid transparent;
      border-radius: 20px;
      background-clip: content-box;
      background-color: $grey2;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }
  }
}

::-webkit-input-placeholder {
  color: $darkGrey;
  font-family: $regularNeutrifPro;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $darkGrey;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $darkGrey;
}
