@import "../../../util/variables";

.under-construction-page {
  height: 80vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tools-icon {
    color: $grey2;
    font-size: 64px;
  }
}

.under-construction-text {
  color: $darkGrey;
  font-family: $boldNeutrifPro;
  margin: 25px 0;
  max-width: 250px;
  text-align: center;
}
