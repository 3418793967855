@import "../../../util/variables";

.sectionPreferenceTitle {
  padding: 2ch;
  padding-bottom: 0;
}

.sectionPreferenceTitle .TitleColumn {
  float: left;
}

.sectionPreferenceTitle .switch {
  margin: 0.5ch 0.5ch;
}

.sectionPreferenceTitle .sessionWeight {
  height: min-content;
}

#inputTitlePreference,
.sessionWeight {
  font-size: 12px;
}

#inputTitlePreference,
#inputSessionWeightPreference,
.sessionWeight {
  border: 1px solid $grey2;
  padding: 1.5ch;
  color: $darkGrey;
  font-family: $regularNeutrifPro;
  border-radius: 3px;
  width: 88.5%;
  outline: none;
}

#inputSessionWeightPreference {
  width: 20%;
  text-align: center;
  margin-left: 5px;
}
