@import "../../../util/variables.scss";

input {
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: text;
  }
}
