@import "../../../util/variables.scss";

.numeric-builder {
  width: 15vw;

  .content {
    padding: 0.8em;
    width: 100%;
    outline: none;
    background-color: white;
    border: 1px solid $grey2;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: text;
    }
  }
}
