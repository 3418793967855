@import "../../../util/variables";

#modal-container .invisible {
  display: none;
}

.modal-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99999;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;

  .modal {
    padding: 29.3px 29px 27.7px 29px;
    position: fixed;
    width: 303px;
    min-height: 160px;
    background-color: white;
    border-radius: 3px;
    border-color: $grey2;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-header {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      align-items: center;
      max-height: 30px;
    }

    .modal-item .icon-button-atom {
      float: right;
    }

    .modal-title {
      font-family: $boldNeutrifPro;
      font-size: 16px;
    }

    .modal-text {
      font-family: $semiBoldNeutrifPro;
      font-size: 14px;
      line-height: 25px;
      margin-top: 5px;
    }

    .modal-item {
      flex: 0 1;
      color: $darkGrey;
    }

    .modal-controls {
      align-self: flex-end;
      display: flex;
      flex-direction: row;
    }

    .modal-right-control {
      margin-left: 5px;
    }

    .modal-button {
      font-family: $boldNeutrifPro;
      font-size: 14px;
      line-height: 18px;
      border-radius: 3px;
      min-width: 90px;
      height: 35px;
      cursor: pointer;
      outline: none;
    }
  }
}
